.fallback-body {
  background-color: #000;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  background-size: cover;
  .contentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
  }
  .icon-logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .spinner{
    border: 6px solid rgb(236, 236, 236);
    border-radius: 50%;
    border-top: 6px solid #d1d400;
    width: 100px;
    height: 100px;
    z-index: 214;
    transform: translate(-50%, -50%);
    transition: all 300ms ease-in;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    position: absolute;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
